<template>
    <slot v-if="!noButton" :open="() => {showDialog=true}">
        <button @click="() => {showDialog=true}" v-bind="$attrs">{{$t('Share with')}}</button>
    </slot>
    <ODialog v-model:show="showDialog" ref="modalRef" @show="handleShown" @hidden="handleHidden" size="lg" :title="$t('Share with Persons')"
        backdrop>
        <div class="o365-dialog-body">
            <ODataGrid :dataObject="dsSharedWith" hideGridMenu showNewRecordsPanel>
                <OColumn name="PersonName" editable width="400" flexWidth="100" disableResize>
                    <template #editor="{row}">
                        <OPersonsLookup v-model="row.PersonName" :whereClause="`ID <> ${userSession.personId}`" 
                            :bind="(sel)=>{row.Person_ID = sel.ID; row.PersonName = sel.Name; row.Layout_ID = props.layoutId }"/>
                    </template>
                </OColumn>
            </ODataGrid>
        </div>
    </ODialog>
</template>

<script setup lang="ts">
import { OModal, ODialog } from 'o365-ui-components';
import { OPersonsLookup } from  'o365-system-lookups';
import { userSession } from 'o365-modules';
import { ref } from 'vue';

const props = defineProps<{
    layoutId: number;
    noButton: boolean;
}>();

const emit = defineEmits<{
    (e: 'hidden' ): void
}>();

const modalRef = ref<OModal|null>(null);
const showDialog = ref(false);
const dsSharedWith = $getOrCreateDataObject({
    id: 'o_dsLayouts_SharedWith',
    appId: 'site',
    viewName: 'sviw_O365_SharedLayouts',
    uniqueTable: 'stbv_O365_SharedLayouts',
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    maxRecords: 50,
    fields: [
        { name: 'PrimKey' }, 
        { name: 'ID', type: 'number' },
        { name: 'Person_ID', type: 'number' },
        { name: 'Layout_ID', type: 'number' },
        { name: 'PersonName' }
    ]
});


function handleShown() {
    if (props.layoutId) {
        dsSharedWith.recordSource.whereClause = `[Layout_ID] = '${props.layoutId}'`;
        dsSharedWith.load();
    } else {
        dsSharedWith.storage.clearItems();
        dsSharedWith.batchData.storage.clearItems();
    }
}

function handleHidden() {
    dsSharedWith.storage.clearItems();
    dsSharedWith.batchData.storage.clearItems();
    emit('hidden');
}

function show() {
    showDialog.value = true;
}

defineExpose({ modalRef, show});
</script>